.container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
}

.message-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}