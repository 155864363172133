.sub-table
  .ant-table
  .ant-table-container
  .ant-table-content
  table
  thead.ant-table-thead
  .ant-table-cell {
  background-color: #37b4de;
  color: #fff;
}
