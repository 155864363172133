.back-to-login {
    color: blue;
    cursor: pointer;
    margin-top: 5px;
    margin-left: 5px;
  }
  
  .image-container {
    text-align: end;
  }
  
  .quote-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 16px;
  }
  
  .license-container {
    background: #d9ecff;
    color: #272727;
    border-radius: 10px;
    padding: 10px;
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  
  .license-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 30%;
  }
  
  .icon-button {
    font-size: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #538fcb;
  }
  
  .total-container {
    background: #5e5e5e;
    color: #f1c946;
    border-radius: 10px;
    margin: 20px;
    padding: 0px 20px;
    width: fit-content;
    cursor: pointer;
    text-align: center;
  }
  
  .disabled-total {
    background: #c3c3c3;
    color: #ebebe4;
  }
  
  .info-container {
    font-size: 18px;
    color: #37b4de;
    font-weight: 600;
    font-style: italic;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  