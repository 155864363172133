body {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0px;
}

.hide-arrows .ant-input-number-handler-wrap {
  display: none;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
}

.content {
  flex: 1;
}